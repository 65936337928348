
















import { Component, Prop, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import sbrSigning from "@/api/sbr_signing";
import { TaskCategory } from "@/models/task";

@Component({
  components: {
    Loader,
  },
})
export default class DocumentsSbrSigningCallback extends Vue {
  @Prop({ default: "" })
  private documentId!: string;

  @Prop({ default: undefined })
  readonly taskUrl?: string;

  private isLoading: boolean = false;

  mounted() {
    this.isLoading = true;
    this.sbrSigningComplete()
      .then((data) => {
        this.returnToDocumentList();
      })
      .catch((error) => {})
      .finally(() => {
        this.isLoading = false;
      });
  }

  sbrSigningComplete(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      sbrSigning.sbrsigning
        .finish(this.documentId)
        .then((data) => {
          resolve(true);
        })
        .catch((data) => {
          this.$snotify.error(
            "Er is iets mis gegaan bij het verwerken van de handtekening."
          );
          resolve(false);
        });
    });
  }

  private getTaskUrlCategory(): TaskCategory | null {
    try {
      return new URL(this.taskUrl || "").searchParams.get(
        "category"
      ) as TaskCategory;
    } catch (e) {
      return null;
    }
  }

  private returnToDocumentList() {
    const category: TaskCategory = this.getTaskUrlCategory() || "my";
    this.$router.push({
      name: "documents",
      query: { category },
    });
  }
}
